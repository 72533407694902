import * as React from "react";
import "./ContactUsPage.css";
import { Paper, Grid } from "@mui/material";
import Iframe from "react-iframe";
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
import getLanguage from '../../Translation/Translation';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Container from '@mui/material/Container';
import { styled } from "@mui/material/styles";
import { Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import dayjs, { Dayjs } from "dayjs";
import DatePicker from '@mui/lab/DatePicker';
import { Formik, FormikProps } from "formik";
import { ContactUs } from "../../../types/contactus.type";
import { useAppDispatch } from "../../..";
import * as contactusActions from "../../../actions/contactus.action";
import { useNavigate } from "react-router-dom";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
type ContactUsPageProps = {
  //
};

// export const languages = [
//   {
//     code: 'en',
//     name: 'English'
//   },
//   {
//     code: 'th',
//     name: 'Thai'
//   }
// ];

const gridStyles = {
  backgroundColor: "gray",
  marginLeft: "auto",
  marginRight: "auto",
  paddingRight: 1,
  paddingBottom: 1
};

const Contents = styled(Paper)(({ theme }) => ({
  ...theme.typography.h6,
  padding: theme.spacing(1),
  textAlign: "center"
}));

const ContactUsPage: React.FC<any> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showlanguage, setShowlanguage] = React.useState(`${i18n.language}`);
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "left" },
    buttons: { marginTop: 2 },
  };

  const handleChange = (event: SelectChangeEvent) => {
    setShowlanguage(event.target.value as string);
  };
  const onLanguageClick = (code: any) => {
    setShowlanguage(code);
    i18n.changeLanguage(code);
    // i18next.changeLanguage(code);
  };
  const languages = [
    {
      code: 'en',
      name: 'English'
    },
    {
      code: 'th',
      name: 'ไทย'
    }
  ];
  // function onLanguageClick(code){
  //   i18next.changeLanguage(code);
  // }


  const FormContactUs = ({ handleSubmit, handleChange, isSubmitting, values }: FormikProps<ContactUs>) => {
    return (
      <form onSubmit={handleSubmit} >
        <Typography sx={{marginBottom:'-10px'}} variant="inherit"  component="h6">
{t('Name')}
</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          onChange={handleChange}
          value={values.name}
          autoComplete="email"
          autoFocus
          size="small"
        />
        
  <Typography sx={{marginBottom:'-10px',marginTop:'10px'}} variant="inherit"  component="h6">
{t('Email Address')}
</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="email_address"
          onChange={handleChange}
          value={values.email_address}
          size="small"
          
          helperText={t('ContactUs Email helperText')}
          
          sx={{ '.MuiFormHelperText-root': {
            marginLeft: "0px",
          },}}
        />

         <Typography sx={{marginBottom:'-10px',marginTop:'10px'}} variant="inherit"  component="h6">
{t('ContactUs Contact Number')}
</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="contact_number"
          onChange={handleChange}
          value={values.contact_number}
          size="small"
        />

                 <Typography sx={{marginBottom:'-10px',marginTop:'10px'}} variant="inherit"  component="h6">
{t('Message')}
</Typography>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="message"
          onChange={handleChange}
          value={values.message}
           multiline
           rows={3}
        />
        <br />

     
  
        <Stack direction="row" spacing={1} sx={classes.buttons}>
          <Button
            type="submit" 
            variant="contained" 
            sx={{ backgroundColor: '#EC994B', color:'#ffffff', margin: 'auto', width: '20%' , '&:hover': {backgroundColor: '#E8630A'} }}
          >
            {t('Send')}
          </Button>
        </Stack>
        {/* <a onClick={() => navigate("/register")}>
          Register Now
          </a> */}
      </form>
    );
  };


  const initialValues: ContactUs = { name: "", email_address: "", contact_number: "", message: "" };
  return (
    <Container fixed sx={{ mt: 3, padding: "2rem 0" }}>
                <Grid className='contact-row'>
                    <Grid className='contact-col'>
                    <Typography sx={{color:'#4F4A41',marginBottom: '2rem',textAlign:'left'}} variant="h3"  component="h3">
                    {t('Contact SME Mate')}
</Typography>
<Typography sx={{color:'#4F4A41',marginBottom: '1rem',textAlign:'left'}} variant="h6"  component="h6">
{t('Address: ')}{t('942/69 Charn Issara Tower 1, 2nd Floor, Rama 4 Road Suriyawong Sub-district, Bang Rak District, Bangkok 10500')}
</Typography>

<Typography sx={{color:'#4F4A41',marginBottom: '1rem',textAlign:'left'}} variant="h6"  component="h6">
{t('Contact Number: ')}(66) 2233-0055, (66) 2233-0068
</Typography>

<Typography sx={{color:'#4F4A41',marginBottom: '1rem',textAlign:'left'}} variant="h6"  component="h6">
{t('Email Address')}: contact@dcm.co.th
</Typography>
                  
                    </Grid>
                </Grid>
              
                <Grid container spacing={2} columns={16} >
                    <Grid item xs={8}>
                    <Formik
                    onSubmit={async(values, {}) => {
                      dispatch(verifyEmailActions.sendMailRequest(values, navigate));
                    }}
                  initialValues={initialValues}
            >
              {(props) => FormContactUs(props)}
            </Formik>
                    </Grid>
                    <Grid item xs={8}>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3875.8082351059056!2d100.529617233727!3d13.730056970676168!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29f2bc2033193%3A0x245a54406c70d286!2sCharn%20Issara%20Tower%201!5e0!3m2!1sen!2sth!4v1670505233182!5m2!1sen!2sth"
                            className='map'
                            style={{ border: "0" }} 
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade"
                            title="myFrameMap"
                            width="100%" 
                            height="100%"
                        />
                    </Grid>
                </Grid>
  
      </Container>
  

  
  );
};

export default ContactUsPage;

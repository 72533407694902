import * as React from "react";
import { useNavigate } from "react-router-dom";
import { Formik, FormikProps } from "formik";
import { Alert, Box, Button, Card, CardContent, Stack, SxProps, TextField, Theme, Typography } from "@mui/material";
import { Register } from "../../../types/register.type";
import { Provinces } from "../../../types/provinces.type";
import { httpClient } from "../../../services/httpclient";
import { server } from "../../../services/Constants";
import * as registerActions from "../../../actions/register.action";
import * as provincesActions from "../../../actions/provinces.action";
import * as verifyEmailActions from "../../../actions/verifyemail.action";
import { useDispatch, useSelector } from "react-redux";
import { RootReducers } from "../../../reducers";
import { useAppDispatch } from "../../..";
import Link from "@mui/material/Link";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import ErrorIcon from "@mui/icons-material/Error";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Swal from "sweetalert2";
import Paper from "@mui/material/Paper";
import CloseIcon from "@mui/icons-material/Close";
import Fade from "@mui/material/Fade";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs, { Dayjs } from "dayjs";
import i18next from 'i18next';

type RegisterPageProps = {
  //
};

export default function RegisterPage() {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const registerReducer = useSelector((state: RootReducers) => state.registerReducer);
  const ProvincesReducer = useSelector((state: RootReducers) => state.provincesReducer);
  const VerifyEmailReducer = useSelector((state: RootReducers) => state.verifyemailReducer);
  const [showcontact_province, setShowcontact_province] = React.useState("");
  const [showcompany_province, setShowcompany_province] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [disablebtn, setdisablebtn] = React.useState(true);
  const [alertverifyemail, setalertverifyemail] = React.useState(true);

  const [date_of_birth, setdate_of_birth] = React.useState(dayjs());

  const calculateAge = (dateOfBirth: any | null) => {
    if (!dateOfBirth) return null; // ถ้าไม่มีวันเกิดให้คืนค่า null
  
    const birthDate = dayjs(dateOfBirth); // แปลงวันเกิดเป็น dayjs object
    if (!birthDate.isValid()) return null; // ตรวจสอบว่าวันที่ที่แปลงมาถูกต้องหรือไม่
    
    const currentDate = dayjs(); // วันที่ปัจจุบัน
    const age = currentDate.diff(birthDate, 'year'); // คำนวณอายุเป็นปี
    return age;
  };
  
  const onChangecontact_provinces = (id: any) => {
    setShowcontact_province(id);
  };

  const onChangecompany_provinces = (id: any) => {
    setShowcompany_province(id);
  };

  const handleClickOpen = async (dataemail: any) => {
    // const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const validRegex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (dataemail === "" || !dataemail || /^\s*$/.test(dataemail)) {
      Swal.fire({
        title: `${t("Unable to register")}`,
        text: `${t("Please enter an email address")}`,
        icon: "warning",
        // iconColor: '#30a702',
        confirmButtonText: `${t("Close")}`,
        confirmButtonColor: "#3085d6",
      });
      setOpen(false);
    } else {
      if (dataemail.match(validRegex)) {
        // setdisablebtn(false)
        // funtion send email
        dispatch(verifyEmailActions.confirmemail({ email: dataemail }));
        setverifyotp({
          verifyotp1: "",
          verifyotp2: "",
          verifyotp3: "",
          verifyotp4: "",
          verifyotp5: "",
          verifyotp6: "",
        });
        dispatch(verifyEmailActions.setVerifyEmailFetchingToState());
        // setalertverifyemail(false)
        // setOpen(true);
      } else {
        Swal.fire({
          title: `${t("Unable to register")}`,
          text: `${t("Invalid email format")}`,
          icon: "warning",
          confirmButtonText: `${t("Close")}`,
          confirmButtonColor: "#3085d6",
        });
        setOpen(false);
      }
    }
  };

  const handleClose = () => {
    setverifyotp({
      verifyotp1: "",
      verifyotp2: "",
      verifyotp3: "",
      verifyotp4: "",
      verifyotp5: "",
      verifyotp6: "",
    });
    setOpen(false);
  };

  const [getemail, setvgetemail] = React.useState("");
  const handleChangeemail = (data: any) => {
    setvgetemail(data);
    // console.log(data)
  };

  const validateotp = /^[0-9\b]+$/;

  React.useEffect(() => {
    dispatch(provincesActions.getAllProvinces());
    dispatch(registerActions.setRegisterFetchingToState());
    dispatch(verifyEmailActions.setVerifyEmailFetchingToState());
  }, []);

  React.useEffect(() => {
    if (VerifyEmailReducer.resultEmail) {
      setalertverifyemail(false);
      setOpen(true);
      return;
    } else if (VerifyEmailReducer.isEmailError) {
      setdisablebtn(true);
      dispatch(verifyEmailActions.setVerifyEmailFetchingToState());
      Swal.fire({
        title: `${t("Unable to register")}`,
        text: `${t("This email is already registered")}`,
        icon: "error",
      });

      return;
    } else if (VerifyEmailReducer.result) {
      setOpen(false);
      setdisablebtn(false);
      dispatch(verifyEmailActions.setVerifyEmailFetchingToState());
      Swal.fire({
        title:`${t("Register")}`,
        text:`${t("Email verified successfully")}`,
        icon: "success",
      });
    } else if (VerifyEmailReducer.isError) {
      setalertverifyemail(true);
      setTimeout(() => {
        setalertverifyemail(false);
      }, 2000);
    } else {
    }
  }, [VerifyEmailReducer]);

  React.useEffect(() => {
    if (registerReducer.result) {
      // Swal.fire({
      //   title: 'สมัครสมาชิก !!',
      //   text: 'สมัครสมาชิก สำเร็จ !!',
      //   icon: 'success',
      // }).then((result) => {
      //   navigate("/home")
      // })
      // dispatch(registerActions.setRegisterFetchingToState());

      return;
    } 
    else if (registerReducer.isErrorusername) {
      dispatch(registerActions.setRegisterFetchingToState());
      Swal.fire({
        title: `${t("Unable to register")}`,
        text: `${t("This username name is already in use")}`,
        icon: "error",
      });
    }
    else if (registerReducer.isError) {
      dispatch(registerActions.setRegisterFetchingToState());
      Swal.fire({
        title: `${t("Unable to register")}`,
        text: `${t("This company name is already in use")}`,
        icon: "error",
      });
    } else {
    }
  }, [registerReducer]);

  //   React.useEffect(() => {
  //     if(VerifyEmailReducer.isError){
  // console.log("fail")
  // Swal.fire({
  //   title: 'สมัครสมาชิก !!',
  //   text: 'อีเมลล์นี้ถูกใช้งานแล้ว !!',
  //   icon: 'error',
  // })
  //     }
  //   }, [VerifyEmailReducer.isError]);

  const handleClickverifyotp = () => {
    dispatch(
      verifyEmailActions.verifyemail({
        email: getemail,
        otp_number:
          verifyotp.verifyotp1 +
          verifyotp.verifyotp2 +
          verifyotp.verifyotp3 +
          verifyotp.verifyotp4 +
          verifyotp.verifyotp5 +
          verifyotp.verifyotp6,
      })
    );
  };

  type VerifyotpType = {
    verifyotp1: string;
    verifyotp2: string;
    verifyotp3: string;
    verifyotp4: string;
    verifyotp5: string;
    verifyotp6: string;
  };
  const [verifyotp, setverifyotp] = React.useState<VerifyotpType>({
    verifyotp1: "",
    verifyotp2: "",
    verifyotp3: "",
    verifyotp4: "",
    verifyotp5: "",
    verifyotp6: "",
  });
  const classes: SxProps<Theme> | any = {
    root: { display: "flex", justifyContent: "center" },
    buttons: { marginTop: 2 },
  };

  const classesdisablebtn: SxProps<Theme> | any = {
    root: {
      "& .MuiInputBase-root.Mui-disabled": {
        backgroundColor: "rgb(245, 245, 245)",
      },
      marginTop: '0.5rem'
    },
  };

  const showFormV1 = ({ handleSubmit, handleChange, isSubmitting, values }: FormikProps<any>) => {
    return (
      <form onSubmit={handleSubmit}>
        <label>Email: </label>
        <input type="text" name="email" id="email" onChange={handleChange} value={values.email} />
        <br />
        <label>Password: </label>
        <input type="text" name="password" id="password" onChange={handleChange} value={values.password} />
        <br />

        <button type="submit" disabled={isSubmitting}>
          Submit
        </button>
        <button onClick={() => navigate(-1)}>Back</button>
      </form>
    );
  };
  const showFormV2 = ({ handleSubmit, handleChange, isSubmitting, values }: FormikProps<Register>) => {
    return (
      <form onSubmit={handleSubmit} onChange={handleChange}>
        {/* Username / Password */}

        <Card sx={{ width: '100%', margin: '2rem 0' }} >
          <CardContent
          sx={{ border: '#ABA9A6 solid 1px', borderRadius: '0', padding: '2rem' }} 
          >
            <Dialog
              disableRestoreFocus
              PaperProps={{
                style: {
                  width: "500px",
                },
              }}
              open={open}
              // onClose={handleClose}
              onClose={(event, reason) => {
                if (reason !== "backdropClick" && reason !== "escapeKeyDown") {
                  setOpen(false);
                }
              }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {/* <Paper elevation={0} sx={{ width:500,maxWidth:500 }}>  */}
              <DialogTitle id="alert-dialog-title">
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                {t("Confirm Emai")}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">{t("Verification code from emai")}</DialogContentText>
                <Grid
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  alignItems="center"
                  justifyContent="center"
                  direction="row"
                  item
                  container
                >
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="verifyotp1"
                    // label="Email"
                    fullWidth
                    value={verifyotp.verifyotp1}
                    onChange={(e) => {
                      let ele = document.querySelectorAll("input");
                      if (e.target.value === "" || validateotp.test(e.target.value)) {
                        setverifyotp({ ...verifyotp, verifyotp1: e.target.value });
                        if (e.target.value === "") {
                          ele[17].focus();
                        } else {
                          ele[18].focus();
                        }
                      }
                    }}
                    onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      // let ele = document.querySelectorAll('input')
                      // let val = e.target
                      // console.log(document.querySelectorAll('input'))
                      // console.info(e.target);
                      // console.info((e.target as HTMLInputElement).value);
                      // if(ele[val-1].value != ''){
                      //   ele[val].focus()
                      // }else if(ele[val-1].value == ''){
                      //   ele[val-2].focus()
                      // }
                    }}
                    inputProps={{ maxLength: 1 }}
                    autoComplete="verifyotp1"
                    autoFocus
                    size="small"
                    sx={{ width: "40px" }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="verifyotp2"
                    // label="Email"
                    fullWidth
                    value={verifyotp.verifyotp2}
                    onChange={(e) => {
                      let ele = document.querySelectorAll("input");
                      if (e.target.value === "" || validateotp.test(e.target.value)) {
                        setverifyotp({ ...verifyotp, verifyotp2: e.target.value });
                        if (e.target.value === "") {
                          ele[18].focus();
                        } else {
                          ele[19].focus();
                        }
                      }
                    }}
                    inputProps={{ maxLength: 1 }}
                    autoComplete="verifyotp2"
                    size="small"
                    sx={{ width: "40px", marginLeft: "5px" }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="verifyotp3"
                    // label="Email"
                    fullWidth
                    value={verifyotp.verifyotp3}
                    onChange={(e) => {
                      let ele = document.querySelectorAll("input");
                      if (e.target.value === "" || validateotp.test(e.target.value)) {
                        setverifyotp({ ...verifyotp, verifyotp3: e.target.value });
                        if (e.target.value === "") {
                          ele[19].focus();
                        } else {
                          ele[20].focus();
                        }
                      }
                    }}
                    inputProps={{ maxLength: 1 }}
                    autoComplete="verifyotp3"
                    size="small"
                    sx={{ width: "40px", marginLeft: "5px" }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="verifyotp4"
                    // label="Email"
                    fullWidth
                    value={verifyotp.verifyotp4}
                    onChange={(e) => {
                      let ele = document.querySelectorAll("input");

                      if (e.target.value === "" || validateotp.test(e.target.value)) {
                        setverifyotp({ ...verifyotp, verifyotp4: e.target.value });
                        if (e.target.value === "") {
                          ele[20].focus();
                        } else {
                          ele[21].focus();
                        }
                      }
                    }}
                    inputProps={{ maxLength: 1 }}
                    autoComplete="verifyotp4"
                    size="small"
                    sx={{ width: "40px", marginLeft: "5px" }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="verifyotp5"
                    // label="Email"
                    fullWidth
                    value={verifyotp.verifyotp5}
                    onChange={(e) => {
                      let ele = document.querySelectorAll("input");

                      if (e.target.value === "" || validateotp.test(e.target.value)) {
                        setverifyotp({ ...verifyotp, verifyotp5: e.target.value });
                        if (e.target.value === "") {
                          ele[21].focus();
                        } else {
                          ele[22].focus();
                        }
                      }
                    }}
                    inputProps={{ maxLength: 1 }}
                    autoComplete="verifyotp5"
                    size="small"
                    sx={{ width: "40px", marginLeft: "5px" }}
                  />
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    id="verifyotp6"
                    // label="Email"
                    fullWidth
                    value={verifyotp.verifyotp6}
                    onChange={(e) => {
                      if (e.target.value === "" || validateotp.test(e.target.value)) {
                        setverifyotp({ ...verifyotp, verifyotp6: e.target.value });
                      }
                    }}
                    autoComplete="verifyotp6"
                    size="small"
                    sx={{ width: "40px", marginLeft: "5px" }}
                  />
                </Grid>
                {/* registerReducer.isError &&  */}
                {VerifyEmailReducer.isError && alertverifyemail && (
                  <Fade in={alertverifyemail}>
                    <Alert severity="error">รหัส OTP ไม่ถูกต้อง</Alert>
                  </Fade>
                )}
                <Grid alignItems="center" justifyContent="center" direction="column" item container>
                  {/* onClick={handleClose} */}
                  <Button onClick={handleClickverifyotp} disabled={alertverifyemail} variant="contained">
                    {t('Confirm')}
                  </Button>
                </Grid>
              </DialogContent>
              <DialogActions>{/* <Button onClick={handleClose}>Disagree</Button> */}</DialogActions>
              {/* </Paper> */}
            </Dialog>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              alignItems="left"
              direction="column"
              container
              item
              sx={{ backgroundColor: "#CE9461", color: "#ffffff", padding: '0.7rem 1rem', marginBottom: '0.5rem' }}
            >
              <Typography>{t("Username and Password")}</Typography>
            </Grid>
            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>
                  {t("Email")}{" "}
                  <span style={{ color: "red" }}>
                    *
                    <Tooltip
                      title={
                        <React.Fragment>
                          <Typography color="inherit">- {t('i.e.')} dcm@gmail.com</Typography>
                        </React.Fragment>
                      }
                      placement="top-start"
                    >
                      <IconButton sx={{ color: '#CE9461' }}>
                        <ErrorIcon />
                      </IconButton>
                    </Tooltip>
                  </span>
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="email"
                  // label="Email"
                  fullWidth
                  disabled={!disablebtn}
                  onChange={(e) => handleChangeemail(e.target.value)}
                  value={values.email}
                  autoComplete="email"
                  autoFocus
                  size="small"
                  sx={{ marginTop: '0'}}
                />
              </Grid>
              <Grid
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                alignItems="right"
                justifyContent="flex-end"
                direction="row"
                item
                container
                sx={{ paddingRight: '1rem'}}
              >
                {/* disabled={registerReducer.isFetching} */}
                <Button
                  onClick={(e) => handleClickOpen(values.email)}
                  disabled={!disablebtn}
                  fullWidth
                  variant="contained"
                  size="small"
                  sx={{ width: 120, backgroundColor: '#CE9461', color: '#ffffff', marginBottom: '0.5rem', '&:hover': {backgroundColor: '#CC704B'}}}
                >
                  {/* {t('checkemail')} */}
                  {t('checkemail')}
                </Button>
              </Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>
                {t('Username')} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="username"
                  //  label="Username"
                  onChange={handleChange}
                  value={values.username}
                  type="text"
                  size="small"
                  autoComplete='new-username'
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>
                {t('Password')} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="password"
                  //  label="Password"
                  onChange={handleChange}
                  value={values.password}
                  type="password"
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>
                {t('Confirm Password')} <span style={{ color: "red" }}>*</span>
                </Typography>
              </Grid>
              <Grid
                xs={12}
                sm={5}
                md={5}
                lg={5}
                xl={5}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="confirmpassword"
                  //  label="confirmpassword"
                  onChange={handleChange}
                  value={values.confirmpassword}
                  type="password"
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={4}
                md={4}
                lg={4}
                xl={4}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <br />

            {/* {registerReducer.isError && <Alert severity="error">Register failed</Alert>}  */}

            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              alignItems="left"
              justifyContent="left"
              direction="column"
              item
              container
              sx={{ margin: 0}}
            >
                {/* <Link href="/register" target="_blank" sx={{ color: '#CE9461', textDecorationColor: '#CE9461', marginLeft: '1rem'}}>
                  {t('Registration guide')}
                </Link> */}
            </Grid>
          </CardContent>
        </Card>

        {/* contact Information */}
        <Card sx={{ width: '100%', margin: '2rem 0'  }}>
          <CardContent sx={{ border: '#ABA9A6 solid 1px', borderRadius: '0', padding: '2rem' }} >
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              alignItems="left"
              direction="column"
              container
              item
              sx={{ backgroundColor: "#CE9461", color: "#ffffff", padding: '0.7rem 1rem', marginBottom: '0.5rem' }}
            >
              <Typography>{t("Contact Information")}</Typography>
            </Grid>
            {/* <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Contact Name")}</Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="contact_name"
                  // label="contact_name"
                  fullWidth
                  onChange={handleChange}
                  value={values.contact_name}
                  autoComplete="contact_name"
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid> */}

        <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Firstname")} <span style={{ color: "red" }}>*</span></Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="first_name"
                  // label="first_name"
                  fullWidth
                  onChange={handleChange}
                  value={values.first_name}
                  autoComplete="first_name"
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Lastname")} <span style={{ color: "red" }}>*</span></Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  id="last_name"
                  // label="last_name"
                  fullWidth
                  onChange={handleChange}
                  value={values.last_name}
                  autoComplete="last_name"
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Date of Birth")} <span style={{ color: "red" }}>*</span></Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                    <MobileDatePicker  
                        
                        value={date_of_birth || dayjs()}
                        onChange={(newValue:any) => {
                          setdate_of_birth(newValue)
                        }}
                        disabled={disablebtn}
                        sx={classesdisablebtn.root}
                        slotProps={{ 
                          textField: { 
                            size: 'small', 
                            fullWidth: true,
                            margin: 'normal', 
                            variant: 'outlined',
                            error: false, // ปิดการแสดงข้อผิดพลาด
                          } 
                        }}
                    />
                </LocalizationProvider>
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>
            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Telephone Number")} <span style={{ color: "red" }}>*</span></Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="contact_phone"
                  //  label="contact_phone"
                  // onChange={handleChange}
                  onChange={(e) => {
                    if (validateotp.test(e.target.value)) {
                      e.target.value = e.target.value;
                      // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,5)
                    } else {
                      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                    }
                  }}
                  inputProps={{ maxLength: 10 }}
                  value={values.contact_phone}
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Address")} <span style={{ color: "red" }}>*</span></Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="contact_address"
                  //  label="contact_address"
                  onChange={handleChange}
                  value={values.contact_address}
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Province")} <span style={{ color: "red" }}>*</span></Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                {
                  i18next.language == 'en' ? (
                    <>
                      <FormControl style={{ width: "100%" }}>
                        {/* <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel> */}
                        <Select
                          size="small"
                          // sx={{ maxWidth: 120, color: '#906427', variant: "outlined" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={showcontact_province}
                          displayEmpty
                          disabled={disablebtn}
                          sx={classesdisablebtn.root}
                          // label={t('language')}
                          // onChange={handleChange}
                        >
                          <MenuItem value="">-- {t("Select Province")} --</MenuItem>
                          {React.Children.toArray(
                            ProvincesReducer.result.map(({ id, name_en}) => (
                              <MenuItem sx={{ color: "#000000" }} value={id} onClick={() => onChangecontact_provinces(id)}>
                                {name_en}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </>
                  ):(
                    <>
                     <FormControl style={{ width: "100%" }}>
                        {/* <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel> */}
                        <Select
                          size="small"
                          // sx={{ maxWidth: 120, color: '#906427', variant: "outlined" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={showcontact_province}
                          displayEmpty
                          disabled={disablebtn}
                          sx={classesdisablebtn.root}
                          // label={t('language')}
                          // onChange={handleChange}
                        >
                          <MenuItem value="">-- {t("Select Province")} --</MenuItem>
                          {React.Children.toArray(
                            ProvincesReducer.result.map(({ id, name_th}) => (
                              <MenuItem sx={{ color: "#000000" }} value={id} onClick={() => onChangecontact_provinces(id)}>
                                {name_th}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </>
                  )
                }
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Post Code")} <span style={{ color: "red" }}>*</span></Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="contact_postcode"
                  //  label=" contact_postcode"
                  // onChange={handleChange}
                  value={values.contact_postcode}
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                  inputProps={{ maxLength: 5 }}
                  onChange={(e) => {
                    if (validateotp.test(e.target.value)) {
                      e.target.value = e.target.value;
                      // e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,5)
                    } else {
                      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                    }
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>
          </CardContent>
        </Card>

        {/* Business Information */}
        <Card sx={{ width: '100%', margin: '2rem 0' }}>
          <CardContent sx={{ border: '#ABA9A6 solid 1px', borderRadius: '0', padding: '2rem'}}>
            <Grid
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              alignItems="left"
              direction="column"
              container
              item
              sx={{ backgroundColor: "#CE9461", color: "#ffffff", padding: '0.7rem 1rem', marginBottom: '0.5rem' }}
            >
              <Typography>{t("Business Information")}</Typography>
            </Grid>
            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Company Name")}</Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  id="company_name"
                  // label=" company_name"
                  fullWidth
                  onChange={handleChange}
                  value={values.company_name}
                  autoComplete="company_name"
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Address")}</Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="company_address"
                  //  label="company_address"
                  onChange={handleChange}
                  value={values.company_address}
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Province")}</Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                {
                  i18next.language == 'en' ? (
                    <>
                      <FormControl style={{ width: "100%" }}>
                        {/* <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel> */}
                        <Select
                          size="small"
                          // sx={{ maxWidth: 120, color: '#906427', variant: "outlined" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={showcompany_province}
                          displayEmpty
                          disabled={disablebtn}
                          sx={classesdisablebtn.root}
                          // label={t('language')}
                          // onChange={handleChange}
                        >
                          <MenuItem value="">-- {t("Select Province")} --</MenuItem>
                          {React.Children.toArray(
                            ProvincesReducer.result.map(({ id, name_en}) => (

                              <MenuItem sx={{ color: "#000000" }} value={id} onClick={() => onChangecompany_provinces(id)}>
                                {name_en}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </>
                  ): (
                    <>
                      <FormControl style={{ width: "100%" }}>
                        {/* <InputLabel id="demo-simple-select-label">{t('language')}</InputLabel> */}
                        <Select
                          size="small"
                          // sx={{ maxWidth: 120, color: '#906427', variant: "outlined" }}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={showcompany_province}
                          displayEmpty
                          disabled={disablebtn}
                          sx={classesdisablebtn.root}
                          // label={t('language')}
                          // onChange={handleChange}
                        >
                          <MenuItem value="">-- {t("Select Province")} --</MenuItem>
                          {React.Children.toArray(
                            ProvincesReducer.result.map(({ id, name_th}) => (

                              <MenuItem sx={{ color: "#000000" }} value={id} onClick={() => onChangecompany_provinces(id)}>
                                {name_th}
                              </MenuItem>
                            ))
                          )}
                        </Select>
                      </FormControl>
                    </>
                  )
                }
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2}}>{t("Post Code")}</Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="company_postcode"
                  //  label="company_postcode"
                  //  onChange={handleChange}
                  value={values.company_postcode}
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                  inputProps={{ maxLength: 5 }}
                  onChange={(e) => {
                    if (validateotp.test(e.target.value)) {
                      e.target.value = e.target.value;
                    } else {
                      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                    }
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Tax ID No")}</Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="company_taxid"
                  //  label="company_taxid"
                  //  onChange={handleChange}
                  value={values.company_taxid}
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                  //  inputProps={{ maxLength: 5 }}
                  onChange={(e) => {
                    if (validateotp.test(e.target.value)) {
                      e.target.value = e.target.value;
                    } else {
                      e.target.value = e.target.value.substring(0, e.target.value.length - 1);
                    }
                  }}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <Grid container>
              <Grid
                xs={12}
                sm={3}
                md={3}
                lg={3}
                xl={3}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <Typography sx={{ marginLeft: 2 }}>{t("Branch")}</Typography>
              </Grid>
              <Grid
                xs={12}
                sm={7}
                md={7}
                lg={7}
                xl={7}
                alignItems="center"
                justifyContent="center"
                direction="column"
                item
                container
              >
                <TextField
                  variant="outlined"
                  margin="normal"
                  // required
                  fullWidth
                  id="company_branch"
                  //  label="company_branch"
                  onChange={handleChange}
                  value={values.company_branch}
                  size="small"
                  disabled={disablebtn}
                  sx={classesdisablebtn.root}
                />
              </Grid>
              <Grid
                xs={12}
                sm={2}
                md={2}
                lg={2}
                xl={2}
                alignItems="left"
                justifyContent="center"
                direction="column"
                item
                container
              ></Grid>
            </Grid>

            <br />

            {/* {registerReducer.isError && <Alert severity="error">Register failed</Alert>}  */}

            
          </CardContent>
        </Card>
        <Stack direction="row" spacing={0} sx={classes.buttons}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={disablebtn}
                sx={{ backgroundColor: "#F2903A", color: "#ffffff", width: '40%', margin: '0 auto' }}
              >
                {t("Submit")}
              </Button>
            </Stack>
      </form>
    );
  };

  const initialValues: Register = {
    email: "",
    username:"",
    password: "",
    confirmpassword: "",
    contact_name: "",
    date_of_birth: null,
    first_name: "",
    last_name: "",
    contact_phone: "",
    contact_address: "",
    contact_province: 0,
    contact_postcode: "",
    company_name: "",
    company_address: "",
    company_province: 0,
    company_postcode: "",
    company_taxid: "",
    company_branch: "",
    member_status: "A",
  };

  return (
    <>
      <Box sx={classes.root}>
        <Card sx={{ boxShadow: "none", maxWidth: '55rem', width: '100%' }}>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2" sx={{ color: "#4F4A41", textAlign: "center" }}>
              {t('Register')}
            </Typography>
            <Formik
              onSubmit={async (values, {}) => {
                if (values.password !== values.confirmpassword) {
                  Swal.fire({
                    title: `${t("Unable to register")}`,
                    text: `${t("Password does not match")}`,
                    icon: "warning",
                  });
                  return;
                }
                if (showcontact_province == "") {
                  Swal.fire({
                    title: `${t("Unable to register")}`,
                    text: `${t("Please choose a province in Contact Information")}`,
                    icon: "warning",
                  });
                  return;
                }
                var age = calculateAge(date_of_birth);
                // alert(age)
                if(Number(age) <= 20){
                  Swal.fire({
                    title: `${t("Unable to register")}`,
                    text: `${t("Please check Date, Age is under 20 years old.")}`,
                    icon: "warning",
                  });
                  return;
                }
                // if (showcompany_province == "") {
                //   Swal.fire({
                //     title: `${t("Unable to register")}`,
                //     text: `${t("Please choose a province in Business Information")}`,
                //     icon: "warning",
                //   });
                //   return;
                // }

                Swal.fire({
                  title: `${t('Are you sure you want to submit?')}`,
                  text: `${t('Your contact information cannot be changed after registered.')}`,
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: `${t('Register')}`,
                  cancelButtonText: `${t('Cancel')}`
                }).then((result) => {
                  if (result.isConfirmed) {
                      dispatch(
                        registerActions.register(
                          {
                            ...initialValues,
                            ...values,
                            contact_province: Number(showcontact_province),
                            company_province: Number(showcompany_province),
                            date_of_birth: date_of_birth.format('YYYY-MM-DD')
                          },
                          navigate
                        )
                      );
                  }
                });
                
              }}
              initialValues={initialValues}
              validateOnChange={false}
            >
              {(props) => showFormV2(props)}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
// export default RegisterPage;
